import Image from "next/legacy/image";
import Link from "next/link";
import React from "react";
import Meta from "../components/Meta";

function Error_page() {
  return (
    <div>
      <Meta title="404 Page Not Found | ArbiPegs | NFT marketplace on #Arbitrum" />
      <div className="pt-[5.5rem] lg:pt-24">
        {/* <!-- 404 --> */}
        <section className="dark:bg-jacarta-900 relative py-16 md:py-24">
          
          <div className="container">
            <div className="mx-auto max-w-lg text-center">
              <span className="mb-5 -mt-10 inline-block dark:hidden">
                <Image
                  src="/images/404loading2.gif"
                  width={1300}
                  height={758}
                  alt="gradient"
                />
              </span>
              <span className="mb-5 -mt-10 hidden dark:inline-block">
                <Image
                  src="/images/404loading.gif"
                  width={1300}
                  height={758}
                  alt="gradient"
                />
              </span>
              <h1 className="text-jacarta-700 font-display -mt-10  mb-6 text-4xl dark:text-white md:text-6xl">
                Page Not Found!
              </h1>
              <p className="dark:text-jacarta-300 mb-12 text-lg leading-normal">
                Oops! The page you are looking for does not exist. It might have
                been moved or deleted.
              </p>
              <Link
                href="/"
                className="bg-accent hover:bg-accent-dark inline-block rounded-xl py-3 px-8 text-center font-semibold text-white transition-all">
                
                  Navigate Back Home
                
              </Link>
            </div>
          </div>
        </section>
        {/* <!-- end 404 --> */}
      </div>
    </div>
  );
};

export default Error_page;
